<template>
  <div class="mt-5 pt-2 mx-auto" style="max-width: 1000px;">
    <a-steps v-model:current="currentStep">
      <a-step disabled title="Шаг 1" description="Контактные &nbsp; данные">
        <template #icon>
          <contacts-outlined />
        </template>
      </a-step>
      <a-step disabled title="Шаг 2" description="Реквизиты компании">
        <template #icon>
          <audit-outlined />
        </template>
      </a-step>
      <a-step disabled title="Шаг 3" description="Информация о заведении">
        <template #icon>
          <form-outlined />
        </template>
      </a-step>
<!--      <a-step disabled title="Шаг 4" description="Фискализация &nbsp;&nbsp;&nbsp;&nbsp; (по умолчанию)">-->
<!--        <template #icon>-->
<!--          <qrcode-outlined />-->
<!--        </template>-->
<!--      </a-step>-->
      <a-step disabled title="Шаг 4" description="Филиалы">
        <template #icon>
          <shop-outlined />
        </template>
      </a-step>
    </a-steps>
    <contacts-view
      v-show="currentStep === 0"
      @success="contactSuccess"
      @form="updateForm"
    />
    <account-view
      v-show="currentStep === 1"
      :form="form"
      @success="accountSuccess"
      @back="currentStep = 0"
    />
    <info-view
      v-show="currentStep === 2"
      :form="form"
      @success="infoSuccess"
      @back="currentStep = 1"
    />
<!--    <fiscal-view-->
<!--      v-show="currentStep === 3"-->
<!--      :form="form"-->
<!--      @success="fiscalSuccess"-->
<!--      @back="currentStep = 2"-->
<!--    />-->
    <store-view
      :form="form"
      v-show="currentStep === 3"
      @success="register"
      :loading="loading"
      @back="currentStep = 2"
    />
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Есть аккаунт?</span>
      <router-link to="/auth/login" class="vb__utils__link">
        Авторизуйтесь
      </router-link>
    </div>
    <a-modal
      :width="800"
      :closable="false"
      v-model:visible="signModal"
      title="Подписать заявление"
      :confirm-loading="agreementLoading"
    >
      <div class="text-center w-100">
<!--        <iframe :src="`https://view.officeapps.live.com/op/embed.aspx?src=${agreement}`" frameborder="0" style="width: 100%; height: 600px"></iframe>-->
        <iframe :src="`${agreement}#toolbar=0`" frameborder="0" style="width: 100%; height: 600px"></iframe>
      </div>
      <template #footer>
        <a-button key="back" @click="copy" :loading="copyLoading">Копировать ссылку</a-button>
        <a-button key="submit" type="primary" :loading="agreementLoading" @click="certificateModal = true">Подписать</a-button>
      </template>
      <a-list-item>
<!--        <a-list-item-meta>-->
<!--          <template #title>-->
<!--            <a :href="agreement">Пользовательское соглашение</a>-->
<!--          </template>-->
<!--          <template #avatar>-->
<!--            <a-avatar :size="50" shape="square" style="background-color: #eeeeee">-->
<!--              <template #icon><FileWordTwoTone /></template>-->
<!--            </a-avatar>-->
<!--          </template>-->
<!--        </a-list-item-meta>-->
      </a-list-item>
    </a-modal>
    <a-modal
      :width="300"
      v-model:visible="certificateModal"
      title="Выберите сертификат"
      ok-text="Подписать"
      cancel-text="Закрыть"
      @ok="sign"
      @cancel="signModal = false"
      :confirm-loading="agreementLoading"
    >
      <a-select
        @change="chooseCertificate"
        placeholder="Выберите сертификат"
        label-in-value
        style="width: 100%"
      >
        <a-select-option :disabled="cert.validTo < new Date() || ![cert['TIN'], cert['PINFL']].includes(tin)" v-for="cert in certificates" :key="cert.name" :value="cert.name">
          {{ getCertificateLabel(cert) }}
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      :width="500"
      v-model:visible="successModal"
      title="Успешно"
      :closable="false"
      :confirm-loading="agreementLoading"
    >
      Договор успешно подписан. Проверьте вашу почту {{ form.email }}, туда отправлен пароль для доступа в кабинет
      <template #footer>
        <a-button key="back" @click="download">Скачать договор</a-button>
        <a-button key="submit" type="primary" :loading="agreementLoading" @click="login">Войти</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  ShopOutlined,
  FormOutlined,
  AuditOutlined,
  QrcodeOutlined,
  ContactsOutlined,
} from "@ant-design/icons-vue";
import {onMounted, ref} from "vue";
import EIMZO from "@/helpers/e-imzo";
import apiClient from "@/services/axios";
import {useRouter, useRoute} from "vue-router";
import InfoView from "@/views/auth/register/InfoView.vue";
import StoreView from "@/views/auth/register/StoreView.vue";
// import FiscalView from "@/views/auth/register/FiscalView.vue";
import AccountView from "@/views/auth/register/AccountView.vue";
import ContactsView from "@/views/auth/register/ContactsView.vue";
import {notification} from "ant-design-vue";

export default {
  name: 'VbRegister',
  components: {
    InfoView,
    StoreView,
    // FiscalView,
    AccountView,
    ContactsView,
    ShopOutlined,
    FormOutlined,
    AuditOutlined,
    // QrcodeOutlined,
    ContactsOutlined,
  },
  setup() {
    const agreementLink = ref('')
    const adminId = ref(null)
    const verified = ref(false)
    const router = useRouter()
    const route = useRoute()
    const agreement = ref(null)
    const loading = ref(false)
    const agreementLoading = ref(false)
    const copyLoading = ref(false)
    const successModal = ref(false)
    const tin = ref(null)
    const certificateModal = ref(false)
    const certificate = ref()
    const certificates = ref([])
    const signModal = ref(false)
    const form = ref({})
    const currentStep = ref(0)
    onMounted(async () => {
      try {
        const eimzo = new EIMZO
        await eimzo.install()
        certificates.value = await eimzo.listAllUserKeys()
      } catch (e) {
        // console.error(e)
      }
      if (route.query.commission && route.query.code === 'hTdop3t734LRk') {
        form.value.commission = route.query.commission
      }
      if (route.query.claim_id) {
        apiClient.post(`/auth/application?claim=${route.query.claim_id}`).then(({data}) => {
            form.value.tax = data.data.tax
            form.value.pos_type = data.data.pos_type
            form.value.name = data.data.name
            form.value.stores = data.data.stores
          })
          .catch(() => {
            // console.log(err)
          })
          .finally(() => {})
      }
    })
    const contactSuccess = contact => {
      currentStep.value = 1
      form.value.phone = contact.phone
      form.value.email = contact.email
      form.value.id = contact.id
      delete form.value.tax
      saveApplication(form.value.id, form.value)
    }
    const updateForm = application => {
      form.value.tin = application.tin
      form.value.mfo = application.mfo
      form.value.account_no = application.account_no
      form.value.name = application.name
      form.value.logo = application.logo
      form.value.background = application.background
      form.value.pos_type = application.pos_type
      form.value.wallet_type = application.wallet_type
      form.value.social = application.social
      form.value.tax = application.tax
      form.value.spic = application.spic
      form.value.code = application.code
      form.value.product = application.product
      form.value.price = application.price
      form.value.stores = application.stores
    }
    const accountSuccess = account => {
      currentStep.value = 2
      form.value.certificate = account.value
      form.value.tin = account.tin
      form.value.fio = account.fio
      form.value.company = account.company
      form.value.position = account.position
      form.value.mfo = account.mfo
      form.value.account_no = account.account_no
      delete form.value.tax
      saveApplication(form.value.id, form.value)
    }
    const infoSuccess = info => {
      currentStep.value = 3
      form.value.name = info.name
      form.value.logo = info.logo
      form.value.background = info.background
      form.value.pos_type = info.pos_type
      form.value.wallet_type = info.wallet_type
      form.value.social = info.social
      delete form.value.tax
      saveApplication(form.value.id, form.value)
    }
    // const fiscalSuccess = fiscal => {
    //   currentStep.value = 4
    //   form.value.tax = fiscal.tax ? 1 : 0
    //   form.value.spic = fiscal.spic
    //   form.value.code = fiscal.code
    //   form.value.product = fiscal.product
    //   form.value.price = fiscal.price
    //   saveApplication(form.value.id, form.value)
    // }
    const register = stores => {
      loading.value = true
      apiClient.post('/auth/signup', {...form.value, stores}).then(response => {
        if (response?.data?.data?.['agreement']) {
          signModal.value = true
          agreement.value = response.data.data['agreement']
          adminId.value = response.data.data['id']
          agreementLink.value = 'https://boss.rhmt.uz/auth/sign/' + response.data.data['id'] + '/' + response.data.data['recipient']
        } else if (response?.data?.data?.['recipient']) {
          loading.value = true
          apiClient.get('/auth/agreement?user=' + response.data.data['id'] + '&account=' + response.data.data['recipient'])
            .then(res => {
              signModal.value = true
              agreement.value = res.data.data['agreement']
              tin.value = res.data.data.tin
              adminId.value = response.data.data['id']
              agreementLink.value = 'https://boss.rhmt.uz/auth/sign/' + response.data.data['id'] + '/' + response.data.data['recipient']
            }).finally(() => {
              loading.value = false
            })
        } else {
          notification.warning({
            message: 'Ошибка при создании договора'
          })
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        loading.value = false
      })
    }
    const saveApplication = (id, data) => {
      let url = `/auth/application?admin=${id}`
      if (route.query.claim_id) {
        url = `/auth/application?claim=${route.query.claim_id}`
      }
      apiClient.post(url, data)
        .then(({data}) => {
          form.value.tax = data.data.tax
        })
        .catch(() => {
          // console.log(err)
        })
        .finally(() => {})
    }
    const getCertificateLabel = (cert) => {
      if (cert['O']) {
        return `${cert['O']} (${cert['TIN']})`
      }
      return `${cert['CN']} (${cert['PINFL']}`
    }
    const chooseCertificate = (cert) => {
      certificate.value = cert.key
    }
    const sign = async () => {
      if (!certificate.value)
        return;
      verified.value = false
      agreementLoading.value = true
      try {
        let id = ''
        const eimzo = new EIMZO()
        const selectedCertificate = await certificates.value.find(cert => cert.name === certificate.value)
        await eimzo.loadKey(selectedCertificate).then(key => id = key.id)
        const result = await eimzo.signPkcs7(selectedCertificate, id)
        // const token = await eimzo.getTimestampToken(result.signature_hex)
        // console.log(token)
        // console.log(result)
        apiClient.post('auth/eimzo-timestamp', {base64: result['pkcs7_64']}).then((res) => {
          apiClient.post('auth/eimzo-verify', {base64: res.data.data['pkcs7b64'], id: adminId.value}).then((res) => {
            if (res.data.data) {
              signModal.value = false
              successModal.value = true
              verified.value = true
            }
          }).catch(() => {
            verified.value = false
          }).finally(() => {
            agreementLoading.value = false
          })
        }).catch(() => {
          verified.value = false
        }).finally(() => {
          agreementLoading.value = false
        })
        agreementLoading.value = false
      } catch (e) {
        // console.log(e, 'try catch')
        verified.value = false
        agreementLoading.value = false
      }
    }

    const login = () => {
      router.push({ name: 'login' })
    }
    const copy = async () => {
      copyLoading.value = true
      const link = document.createElement('textarea');
      link.value = agreementLink.value
      document.getElementsByTagName('body')[0].appendChild(link)
      link.select()
      link.setSelectionRange(0, 9999)
      document.execCommand('copy')
      link.remove()
      setTimeout(() => {
        notification.info({
          message: 'Ссылка скопирована'
        })
        copyLoading.value = false
      }, 500)
      // router.push({ name: 'login' })
    }
    const download = () => {
      window.open(agreement.value, '_blank')
    }

    return {
      tin,
      form,
      loading,
      verified,
      signModal,
      agreement,
      currentStep,
      copyLoading,
      successModal,
      certificates,
      agreementLink,
      certificateModal,
      agreementLoading,
      sign,
      copy,
      login,
      download,
      register,
      updateForm,
      infoSuccess,
      contactSuccess,
      accountSuccess,
      // fiscalSuccess,
      chooseCertificate,
      getCertificateLabel,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
