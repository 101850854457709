<template>
  <div class="card mt-5" :class="$style.container">
    <div class="text-dark font-size-28 mb-3">Филиалы</div>
    <a-form
      :model="storeForm"
      layout="vertical"
      class="mb-4"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <div v-for="(store, id) in storeForm" :key="id">
        <a-divider orientation="left">Филиал №{{ id + 1 }}</a-divider>
        <a-form-item name="name" label="Название филиала">
          <a-input
            v-model:value="store.name"
            placeholder="Введите название филиала"
          />
        </a-form-item>
        <a-form-item name="address" label="Адрес">
          <a-input
            @click="mapModal = id"
            v-model:value="store.address"
            placeholder="Укажите адрес"
          >
            <template #suffix>
              <svg class="my-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                   viewBox="0 0 16 16" fill="none">
                <path
                  d="M8 1C4.6862 1 2 3.6862 2 7C2 8.6563 2.6711 10.156 3.7565 11.2417C4.8422 12.328 7.4 13.9 7.55 15.55C7.57249 15.7974 7.7516 16 8 16C8.2484 16 8.42751 15.7974 8.45 15.55C8.6 13.9 11.1578 12.328 12.2435 11.2417C13.3289 10.156 14 8.6563 14 7C14 3.6862 11.3138 1 8 1Z"
                  fill="#5a5c97" />
                <path
                  d="M8.00002 9.10015C9.15982 9.10015 10.1 8.15994 10.1 7.00015C10.1 5.84035 9.15982 4.90015 8.00002 4.90015C6.84023 4.90015 5.90002 5.84035 5.90002 7.00015C5.90002 8.15994 6.84023 9.10015 8.00002 9.10015Z"
                  fill="white" />
              </svg>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="phone" label="Номер телефона">
          <a-input
            autocomplete="off"
            v-model:value="store.phoneMask"
            @input="updatePhone($event.target.value, id)"
            v-maska="'+998(##) ###-##-##'"
            placeholder="Ввеите номер телефона"
          />
        </a-form-item>
      </div>
      <a-form-item>
        <a-button type="dashed" style="width: 100%" @click="addStore">
          <PlusCircleOutlined />
          Добавить филиал
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="width: 48%" type="default" @click="back">Назад</a-button>
        <a-button style="width: 48%; float: right" :loading="loading" type="primary" html-type="submit" @click="() => {}">Создать аккаунт</a-button>
      </a-form-item>
    </a-form>
    <store-map @hide-map="applyAddress($event, mapModal)" :coords="storeForm[mapModal]?.coordinates ? storeForm[mapModal]?.coordinates : '41.31115, 69.279737'" :modal="mapModal !== null" />
  </div>
</template>
<script>
import {reactive, ref, watch} from "vue";
import {PlusCircleOutlined} from "@ant-design/icons-vue";
import {notification} from "ant-design-vue";
import StoreMap from "@/views/store/map.vue";

export default {
  name: 'StoreView',
  components: {StoreMap, PlusCircleOutlined},
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back', 'success'],
  setup(props, ctx) {
    const mapModal = ref(null)
    const storeForm = reactive([{
      name: '',
      phone: '',
      address: '',
      phoneMask: '',
      coordinates: '',
      address_description: '',
      server_url: '',
      server_login: ''
    }])
    const addStore = () => {
      storeForm.push({
        name: '',
        phone: '',
        address: '',
        phoneMask: '',
        coordinates: '',
        address_description: '',
        server_url: '',
        server_login: ''
      })
    }
    const back = () => {
      ctx.emit('back')
    }
    const handleFinish = () => {
      if (!storeForm[0].name || !storeForm[0].address || !storeForm[0].phone) {
        notification.warning({
          message: 'Пожалуйста добавьте минимум один филиал'
        })
        return;
      }
      ctx.emit('success', storeForm)
    }
    const handleFinishFailed = () => {
      // console.log(errors)
    }
    const applyAddress = (value, id) => {
      if (value.address)
        storeForm[id].address = value.address.toString();
      if (value.address_description)
        storeForm[id].address_description = value.address_description.toString();
      if (value.coordinates)
        storeForm[id].coordinates = value.coordinates.toString();
      mapModal.value = null
    }
    const updatePhone = (input, id) => {
      let x = input
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
      storeForm[id].phoneMask = input;
      storeForm[id].phone = x[0];
    }
    watch(props.form, form => {
      if (form.stores) {
        storeForm.splice(0)
        form.stores.forEach(item => {
          storeForm.push(item)
        })
      }
    })
    return {
      mapModal,
      storeForm,
      back,
      addStore,
      updatePhone,
      handleFinish,
      applyAddress,
      handleFinishFailed,
    }
  }
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>

